import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import React from 'react';
import { Routes, Route } from "react-router-dom"
import PatientInfo from "./components/PatientInfo";
import Navbar from "./components/navbar";
import PdfViewer from "./components/PdfViewer";

function App() {
  return (
    <>
    <Navbar />
    <Routes>
        <Route path="/" element={ <PatientInfo /> } />
        <Route path="search" element={ <PdfViewer /> } />
        <Route path="search/:value" element={ <PdfViewer /> } />
    </Routes>
    
    {/* <PatientInfo /> */}
    {/* <PdfViewer /> */}
    </>   
      
  );
}

export default App;
