import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
   Card,
   Checkbox,
   Space,
   Dropdown,
   Button,
   Cascader,
   Segmented,
   Flex,
   List,
   Typography,
   Divider,
} from "antd";
import {
   SearchOutlined,
   LeftOutlined,
   LeftCircleTwoTone,
   LeftCircleFilled,
   DownOutlined,
} from "@ant-design/icons";
// import localData from "../JSON/data";
import { Worker, Viewer, MinimalButton } from "@react-pdf-viewer/core";
import {
   defaultLayoutPlugin,
   ToolbarProps,
   ToolbarSlot,
} from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { searchPlugin, NextIcon } from "@react-pdf-viewer/search";
import "@react-pdf-viewer/search/lib/styles/index.css";
import axios from "axios";
import queryString from "query-string";

const PdfViewer = () => {
   const searchPluginInstance = searchPlugin();
   const { highlight, jumpToNextMatch } = searchPluginInstance;

   const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
      <Toolbar>
         {(slots: ToolbarSlot) => {
            const {
               CurrentPageInput,
               EnterFullScreen,
               GoToNextPage,
               GoToPreviousPage,
               NumberOfPages,
               Print,
               ShowSearchPopover,
               Zoom,
               ZoomIn,
               ZoomOut,
            } = slots;

            return (
               <Flex
                  align="center"
                  justify="space-between"
                  className="w-100"
                  wrap="nowrap">
                  {/* <div style={{ padding: "0px 2px" }}>
                     <ShowSearchPopover />
                  </div> */}
                  {/* <MinimalButton onClick={jumpToNextMatch}>
                     <NextIcon />
                  </MinimalButton> */}
                  <Flex>
                     <div style={{ padding: "0px 2px" }}>
                        <ShowSearchPopover />
                     </div>
                     <div style={{ padding: "0px 2px" }}>
                        <ZoomOut />
                     </div>
                     <div style={{ padding: "0px 2px" }}>
                        <Zoom />
                     </div>
                     <div style={{ padding: "0px 2px" }}>
                        <ZoomIn />
                     </div>
                  </Flex>
                  <Flex align="center">
                     <div style={{ padding: "0px 2px" }}>
                        <GoToPreviousPage />
                     </div>
                     <div style={{ padding: "0px 2px", width: "4rem" }}>
                        <CurrentPageInput />
                     </div>
                     <div style={{ padding: "0px 5px" }}>
                        Of <NumberOfPages /> Pages
                     </div>
                     <div style={{ padding: "0px 2px" }}>
                        <GoToNextPage />
                     </div>
                  </Flex>
                  <Flex>
                     <div style={{ padding: "0px 2px" }}>
                        <EnterFullScreen />
                     </div>
                     <div style={{ padding: "0px 2px" }}>
                        <Print />
                     </div>
                  </Flex>
               </Flex>
            );
         }}
      </Toolbar>
   );

   const [pdfUrl, setPdfUrl] = useState();
   const [pdfObject, setPdfObject] = useState([]);
   const { value } = useParams();
   const [searchKeyword, setSearchKeyword] = useState(value ? value : "");
   // const [filterResult, setFilterResult] = useState([]);
   const defaultLayoutPluginInstance = defaultLayoutPlugin({
      renderToolbar,
   });
   // const searchPluginInstance = searchPlugin();
   // const { highlight, jumpToNextMatch } = searchPluginInstance;
   const [isDocumentLoaded, setDocumentLoaded] = useState(false);
   const handleDocumentLoad = () => setDocumentLoaded(true);
   const [sortData, setSortData] = useState([]);
   const [searchResult, setSearchResult] = useState([]);
   const [sortType, setSortType] = useState([]);
   const [chartNameSearch, setChartNameSearch] = useState(false);
   const [chartContentSearch, setChartContentSearch] = useState(false);
   const [data, setData] = useState([]);
   const [typedValue, setTypedValue] = useState(searchKeyword);
   const [selectedChart, setSelectedChart] = useState("");
   const [resultList, setResultList] = useState([]);
   const [sortBtnOpen, setSortBtnOpen] = useState(false);
   const [pageNumber, setPageNumber] = useState(1);

   useEffect(() => {
      if (isDocumentLoaded) {
         highlight({
            keyword: searchKeyword,
         });
      }
   }, [isDocumentLoaded]);

   useEffect(() => {
      let element = pdfUrl ? (
         <Viewer
            onDocumentLoad={handleDocumentLoad}
            plugins={[defaultLayoutPluginInstance, searchPluginInstance]}
            fileUrl={pdfUrl}
         />
      ) : pdfUrl == "" ? (
         <h5 key={"no preview"} style={{ textAlign: "center" }}>
            No Preview available
         </h5>
      ) : null;

      setPdfObject([element]);
   }, [pdfUrl]);

   function resultData() {
      if (searchKeyword !== "") {
         setSearchResult(data);
         // setResultList(data);
      } else {
         // setFilterResult([]);
         setPdfObject([]);
         setPdfUrl(undefined);
      }
   }

   function filterSearchResult() {
      let temp;
      if (chartNameSearch && chartContentSearch) {
         // temp = searchResult.filter(result => result.searchType === 2);
         temp = searchResult.filter(
            result => result.filename && result.content
         );
      }

      if (chartNameSearch && !chartContentSearch) {
         // temp = searchResult.filter(result => result.searchType === 1);
         temp = searchResult.filter(result => result.filename);
      }

      if (!chartNameSearch && chartContentSearch) {
         // temp = searchResult.filter(result => result.searchType === 3);
         temp = searchResult.filter(result => result.content);
      }

      if (!chartNameSearch && !chartContentSearch) {
         temp = searchResult;
      }
      setSortData([...temp]);
      setSortType([]);
      setResultList([...temp]);
   }

   function listItem(item) {
      const values = [searchKeyword];
      let markText = item["match_summary"];
      values.forEach(matchKey => {
         const re = new RegExp("\\b" + matchKey + "\\b", "gi");
         markText = markText.replaceAll(
            re,
            str => `<mark class='bg-warning'>${str}</mark>`
         );
      });
      // const re = new RegExp(searchKeyword, "gi");
      // const markText = paraGraph.replaceAll(
      //    re,
      //    `<mark class='bg-warning'>${searchKeyword}</mark>`
      // );

      return (
         // <List.Item>
         <Card
            // type="inner"
            hoverable={true}
            className={`border-0 p-0 mb-2 list-card ${
               selectedChart == item.name ? "bg-dark-subtle" : ""
            }`}
            size="small"
            // onMouseEnter={e =>
            //    e.currentTarget.classList.toggle("bg-secondary-subtle")
            // }
            // onMouseLeave={e =>
            //    e.currentTarget.classList.toggle("bg-secondary-subtle")
            // }
            onClick={e => {
               setSelectedChart(item.name);
               setPdfUrl(item.url);
               setDocumentLoaded(false);
            }}>
            <div>
               <span
                  style={{ fontSize: ".8rem" }}
                  className="float-end badge text-bg-light">
                  {item.date}
               </span>
               <h6
                  title={item.name}
                  style={{ color: "blue" }}
                  // className="fw-semibold"
                  // onClick={() => {
                  //    setPdfUrl(item.url);
                  //    setDocumentLoaded(false);
                  // }}
               >
                  {item.name}
                  <span
                     // style={{ fontSize: ".8rem" }}
                     className="badge bg-dark-subtle text-black ms-2">
                     Match : {item.no_of_matches}
                  </span>
               </h6>
            </div>
            {/* <p className="float-none mb-0">{item["match_summary"]}</p> */}
            <p
               className="float-none mb-0"
               dangerouslySetInnerHTML={{ __html: markText }}></p>
         </Card>
         // </List.Item>
      );
   }

   function getData(keyword) {
      let data = {
         query: keyword,
      };

      axios
         .post("http://18.189.140.178/search", queryString.stringify(data), {
            headers: {
               "Content-Type": "application/x-www-form-urlencoded",
            },
         })
         .then(res => {
            setData(res.data);
            // console.log("response", res);
         });
   }

   useEffect(() => {
      let sort = [];
      if (sortType[0] === "Alphabetical" && sortType[1] === 1) {
         // sort = searchResult.sort((a, b) => {
         sort = sortData.sort((a, b) => {
            let nameA = a.name.toLowerCase();
            let nameB = b.name.toLowerCase();
            return nameA > nameB ? 1 : nameA < nameB ? -1 : 0;
         });
      } else if (sortType[0] === "Alphabetical" && sortType[1] === 2) {
         // sort = searchResult.sort((a, b) => {
         sort = sortData.sort((a, b) => {
            let nameA = a.name.toLowerCase();
            let nameB = b.name.toLowerCase();
            return nameA < nameB ? 1 : nameA > nameB ? -1 : 0;
         });
      }

      if (sortType[0] === "Date" && sortType[1] === 1) {
         // sort = searchResult.sort((a, b) =>
         sort = sortData.sort((a, b) =>
            new Date(a.date) > new Date(b.date)
               ? 1
               : new Date(a.date) < new Date(b.date)
               ? -1
               : 0
         );
      } else if (sortType[0] === "Date" && sortType[1] === 2) {
         // sort = searchResult.sort((a, b) =>
         sort = sortData.sort((a, b) =>
            new Date(a.date) < new Date(b.date)
               ? 1
               : new Date(a.date) > new Date(b.date)
               ? -1
               : 0
         );
      }

      if (sort.length) {
         setResultList([...sort]);
      }
   }, [sortType]);

   useEffect(() => {
      setPdfUrl(undefined);
      setSortType([]);
      getData(searchKeyword);
   }, [searchKeyword]);

   useEffect(() => {
      filterSearchResult();
   }, [chartNameSearch, chartContentSearch, searchResult]);

   useEffect(() => {
      resultData();
   }, [data]);

   const sortByOptions = [
      {
         value: "Alphabetical",
         label: "Alphabetical",
         children: [
            {
               label: "Ascending",
               value: 1,
            },
            {
               label: "Descending",
               value: 2,
            },
         ],
      },
      {
         value: "Date",
         label: "Date",
         children: [
            {
               label: "Ascending",
               value: 1,
            },
            {
               label: "Descending",
               value: 2,
            },
         ],
      },
   ];

   const yearFilterOptions = [
      {
         label: "All",
         value: 0,
      },
      {
         label: "1 Year",
         value: 1,
      },
      {
         label: "3 Years",
         value: 3,
      },
      {
         label: "5 Years",
         value: 5,
      },
   ];

   return (
      <>
         <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="d-flex w-100 justify-content-between align-items-center mt-2">
               <Link to="/">
                  <button type="button" className="btn ms-3">
                     <LeftCircleFilled className="p-1 fs-1 text-secondary" />
                  </button>
               </Link>
               <div className="input-group w-50">
                  <input
                     type="text"
                     className="form-control rounded-start-pill"
                     style={{
                        height: "50px",
                        borderRadius: "50px",
                        background: "#FFF",
                        boxShadow: "0px -5px 23px -5px rgba(0, 0, 0, 0.25)",
                     }}
                     placeholder="Search EHR Documents"
                     value={typedValue}
                     // onChange={e => setSearchKeyword(e.target.value)}
                     onChange={e => setTypedValue(e.target.value)}
                     onKeyUp={e => {
                        if (e.key === "Enter") {
                           setSearchKeyword(typedValue);
                           // resultData();
                        }
                     }}
                  />
                  <div className="input-group-append">
                     {/* <button className="btn rounded-end-pill border border-start-0" type="button">Search</button> */}
                     <button
                        className="btn rounded-end-pill border border-start-0"
                        type="button"
                        style={{ cursor: "pointer" }}
                        onClick={() => setSearchKeyword(typedValue)}>
                        <SearchOutlined />
                     </button>
                  </div>
               </div>
               <div></div>
            </div>
            {/* <div className="mb-3 mt-1"> */}
            {/* <Space> */}
            <Flex
               gap={"large"}
               justify="center"
               align="center"
               className="mt-2 mb-3">
               <div className="me-5 pe-5"></div>
               <Segmented
                  className="fw-semibold bg-dark-subtle"
                  // options={["All", "1 Year", "3 Years", "5 Years"]}
                  options={yearFilterOptions}
                  // value={filterByYear}
                  // onChange={setFilterByYear}
                  onChange={value => console.log(value)}
               />
               <Checkbox
                  className="fw-semibold"
                  checked={chartNameSearch}
                  onChange={e => {
                     setChartNameSearch(e.target.checked);
                     // filterSearchResult();
                  }}>
                  Chart name
               </Checkbox>
               <Checkbox
                  className="fw-semibold"
                  checked={chartContentSearch}
                  onChange={e => {
                     setChartContentSearch(e.target.checked);
                     // filterSearchResult();
                  }}>
                  Chart content
               </Checkbox>
               <Cascader
                  options={sortByOptions}
                  expandTrigger="hover"
                  variant="filled"
                  value={sortType}
                  open={sortBtnOpen}
                  onChange={value => {
                     setSortBtnOpen(false);
                     setSortType(value);
                     setPageNumber(1);
                     // console.log(value);
                  }}
                  dropdownRender={menus => (
                     <div>
                        {menus}
                        <Divider
                           style={{
                              margin: 0,
                           }}
                        />
                        {sortType.length ? (
                           <div>
                              <Button
                                 type="primary"
                                 className="float-end m-2"
                                 onClick={() => {
                                    setSortBtnOpen(false);
                                    setSortType([]);
                                 }}>
                                 Reset
                              </Button>
                           </div>
                        ) : null}
                     </div>
                  )}>
                  <Button
                     shape="round"
                     type={sortType.length ? "primary" : "default"}
                     onClick={() => setSortBtnOpen(!sortBtnOpen)}>
                     <Space>
                        Sort By
                        <DownOutlined />
                     </Space>
                  </Button>
               </Cascader>
               <div className=""></div>
               {/* <Dropdown
                     menu={{
                        items,
                        selectable: true,
                        selectedKeys: sortBySelctedValue,
                        onSelect: item => {
                           sortBySelctedValue[0] = item.key;
                           setSortType(Number(item.key));
                        },
                     }}>
                     <Button style={{ borderRadius: "30px" }}>
                        <Space>
                           Sort By
                           <DownOutlined />
                        </Space>
                     </Button>
                  </Dropdown> */}
            </Flex>
            {/* </Space> */}

            {/* </div> */}
            <Card
               style={{
                  width: "95%",
                  borderRadius: "50px",
                  background: "#FFF",
                  boxShadow: "0px -5px 23px -5px rgba(0, 0, 0, 0.25)",
               }}
               className="ps-4">
               <p
                  className="badge text-bg-light p-0 "
                  style={{ fontSize: ".8rem" }}>
                  {`Search Results : ${resultList.length} `}
               </p>
               <div className="row" key={1}>
                  <div className="col ps-0">
                     {/* <ul className="p-0" style={{ listStyle: "none" }}> */}
                     {/* <Card> */}
                     <List
                        split={false}
                        itemLayout="vertical"
                        // dataSource={sampleData}
                        dataSource={resultList}
                        renderItem={item => listItem(item)}
                        pagination={{
                           current: pageNumber,
                           onChange: page => setPageNumber(page),
                           hideOnSinglePage: true,
                           total: resultList.length,
                           showTotal: (total, range) => (
                              <span className="fw-semibold">{`Showing ${range[0]} - ${range[1]} of ${total} Results`}</span>
                           ),
                        }}
                     />
                     {/* {filterResult}</List> */}
                     {/* </Card> */}
                     {/* </ul> */}
                  </div>

                  <div className="col vh-100" key={2}>
                     <Worker
                        key={pdfUrl}
                        workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                        {pdfObject}
                     </Worker>
                  </div>
               </div>
            </Card>
         </div>
      </>
   );
};

export default PdfViewer;
