import React from "react";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import { Flex } from "antd";

const Navbar = () => {
   return (
      <nav className="navbar pb-3" style={{ backgroundColor: "#02214A" }}>
         <div className="container-fluid align-items-start">
            {/* <a className="navbar-brand"> */}
            <Link to="/" className="ms-4">
               <img
                  src={logo}
                  alt="Logo"
                  height="55"
                  // className="d-inline-block align-text-top ms-3"
               />
            </Link>
            {/* </a> */}
            {/* <div className="col-2 d-flex flex-column justify-content-center align-items-center"> */}
            {/* <div className="container"> */}
            {/* <div className="row"> */}
            {/* <div> */}
            <Flex justify="center" align="center" className="me-3">
               <img
                  src="https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg"
                  title="picture"
                  className="mx-2"
                  style={{
                     display: "inline-block",
                     width: "55px",
                     height: "55px",
                     borderRadius: "50%",
                  }}
               />
               <p
                  className="fs-6 mb-0"
                  style={{
                     display: 'inline-block',
                     color: "#FFF",
                     textAlign: "center",
                     fontFamily: "Inter",
                     fontSize: "26px",
                     fontStyle: "normal",
                     fontWeight: "600",
                     lineHeight: "1.3",
                  }}>
                  JAMES MEDICINE <br /> 512589
               </p>
            </Flex>
            {/* </div> */}
            {/* <div className="col-8 text-white navbar-text"> */}
            {/* <div className='text-white'>
                                    Patients
                                </div> */}
            {/* <p>Practices</p> */}
            {/* </div> */}
            {/* </div> */}
            {/* </div> */}
            {/* </div> */}
         </div>
      </nav>
   );
};

export default Navbar;
