import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Card, Input, Segmented, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import patientNameIcon from "../assets/patientNameIcon.png";
import patientAgeIcon from "../assets/patientAgeIcon.png";
// import sampleData from "../JSON/data";
import axios from "axios";

function PatientInfo() {
   const [searchValue, setSearchValue] = useState(null);
   const navigate = useNavigate();
   const { Search } = Input;
   const [filterByYear, setFilterByYear] = useState("All");
   const [filteredDataByDate, setFilteredDataByDate] = useState([]);
   const [sampleData, setSampleData] = useState([]);
   const [pageNumber, setPageNumber] = useState(1);
   const [currentPage, setCurrentPage] = useState(1);

   useEffect(() => {
      let selectedOption = filterByYear.toLowerCase();
      let filterCharts = [];

      if (selectedOption === "All".toLowerCase()) {
         filterCharts = sampleData;
      }
      if (selectedOption === "1 Year".toLowerCase()) {
         let year = new Date().setFullYear(new Date().getFullYear() - 1);
         filterCharts = sampleData.filter(
            chart => new Date(chart.date) > new Date(year)
         );
      }
      if (selectedOption === "3 Years".toLowerCase()) {
         let year = new Date().setFullYear(new Date().getFullYear() - 3);
         filterCharts = sampleData.filter(
            chart => new Date(chart.date) > new Date(year)
         );
      }
      if (selectedOption === "5 Years".toLowerCase()) {
         let year = new Date().setFullYear(new Date().getFullYear() - 5);
         filterCharts = sampleData.filter(
            chart => new Date(chart.date) > new Date(year)
         );
      }
      setFilteredDataByDate(filterCharts);
   }, [filterByYear, sampleData]);

   useEffect(() => {
      axios
         .get("http://18.189.140.178/api/chartlist")
         .then(function (response) {
            console.log(response.data[0]);
            setSampleData(response.data[0]);
         });
   }, []);

   const tableColumns = [
      {
         title: "#",
         dataIndex: "#",
         align: 'center',
         render: (text, record, index) => (pageNumber - 1) * 10 + index + 1,
         // render: (text, record, index) => index + 1,
      },
      {
         title: "Chart Name",
         // dataIndex: "name",
         // key: "name",
         render: chart => (
            <a
               href={chart.url}
               target="_blank"
               className="text-decoration-none">
               {chart.name}
            </a>
         ),
      },
      {
         title: "Date",
         dataIndex: "date",
         // key: "date",
      },
   ];

   return (
      <>
         <div className="d-flex flex-column justify-content-center align-items-center mt-5">
            <Card
               // title="PATIENT DETAILS"
               bordered={true}
               style={{
                  // width: 450,
                  // height: "281px",
                  fontFamily: "Inter",
                  borderRadius: "30px",
                  background: "#FFF",
                  boxShadow: "0px -5px 40px -11px rgba(0, 0, 0, 0.25)",
               }}
               className="bg-white px-3 pt-2 pb-0">
               <p
                  style={{
                     color: "#02214A",
                     fontSize: "31px",
                     fontStyle: "normal",
                     fontWeight: "600",
                     lineHeight: "normal",
                     textAlign: "center",
                  }}>
                  PATIENT DETAILS
               </p>
               {/* <p
                  style={{
                     display: "inline-block",
                  }}>
                  <img src={patientNameIcon} className="patientIcon" />
                  Some text
               </p> */}
               <table
                  className="table table-borderless"
                  style={{ fontSize: "26px" }}>
                  <tbody>
                     <tr>
                        <th>
                           {/* <i className="fas fa-user fs-5"></i> */}
                           <img src={patientNameIcon} className="patientIcon" />
                        </th>
                        <td>Patient Name</td>
                        <th>ALLEN, Ann M.</th>
                     </tr>
                     <tr>
                        <th>
                           {/* <i className="fas fa-child fs-5"></i> */}
                           <img src={patientAgeIcon} className="patientIcon" />
                        </th>
                        <td>Patient DOB</td>
                        <th>07-Oct-1990</th>
                     </tr>
                  </tbody>
               </table>
            </Card>
            <div className="input-group w-50 mt-5">
               <input
                  type="text"
                  className="form-control rounded-start-pill"
                  style={{
                     // width: "1032px",
                     // height: "76px",
                     height: "50px",
                     borderRadius: "50px",
                     background: "#FFF",
                     boxShadow: "0px -5px 23px -5px rgba(0, 0, 0, 0.25)",
                  }}
                  placeholder="Search EHR Documents"
                  onChange={e => setSearchValue(e.target.value)}
                  onKeyUp={e => {
                     if (e.key == "Enter") {
                        navigate(`search/${searchValue}`);
                     }
                  }}
               />
               <div className="input-group-append">
                  {/* <button className="btn rounded-end-pill border border-start-0" type="button">Search</button> */}
                  {searchValue ? (
                     <Link to={`search/${searchValue}`}>
                        <button
                           className="btn rounded-end-pill border border-start-0"
                           type="button"
                           style={{ cursor: "pointer" }}>
                           <SearchOutlined />
                        </button>
                     </Link>
                  ) : (
                     <button
                        style={{ cursor: "pointer" }}
                        className="btn rounded-end-pill border border-start-0"
                        type="button">
                        <SearchOutlined />
                     </button>
                  )}
               </div>
            </div>
            <Card
               title="EHR Charts list"
               extra={
                  <Segmented
                     className="fw-semibold bg-dark-subtle"
                     options={["All", "1 Year", "3 Years", "5 Years"]}
                     value={filterByYear}
                     onChange={(value) => {
                        setFilterByYear(value);
                        setPageNumber(1);
                     }}
                  />
               }
               className="w-50 mt-3">
               <Table
                  columns={tableColumns}
                  dataSource={filteredDataByDate}
                  rowKey={record => record.name + Math.random() * 100}
                  size="small"
                  pagination={{
                     current: pageNumber,
                     hideOnSinglePage: true,
                     onChange: page => setPageNumber(page),
                     total: filteredDataByDate.length,
                     showTotal: (total, range) => (
                        <span className="fw-semibold">{`Showing ${range[0]} - ${range[1]} of ${total} Charts`}</span>
                     ),
                  }}
               />
               {/* <table className="table table-borderless">
                  <thead>
                     <tr>
                        <th scope="col">#</th>
                        <th scope="col">Chart Name</th>
                        <th scope="col">Date</th>
                     </tr>
                  </thead>
                  <tbody>
                     {filteredDataByDate.map((chart, index) => {
                        return (
                           <tr>
                              <th>{index + 1}</th>
                              <td>
                                 <a
                                    href={chart.url}
                                    target="_blank"
                                    className="text-decoration-none">
                                    {chart.name}
                                 </a>
                              </td>
                              <td>{chart.date}</td>
                           </tr>
                        );
                     })}
                  </tbody>
               </table> */}
            </Card>
         </div>
      </>
   );
}

export default PatientInfo;
